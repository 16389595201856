<template>
  <div>
    <div class="title cl">
      <div class="title-left">
        <span class="title-match"></span>
        <span>新闻与公告</span>
      </div>
      <div class="title-right" @click="goAct()">MORE+</div>
    </div>
    <div class="cl" style="margin-top: 20px">
      <div class="carousel">
        <el-carousel height="393px" :interval="3000">
          <div v-for="(item, index) in news" :key="index">
            <el-carousel-item v-if="item.image && index < 5">
              <img :src="baseImg + item.image" @click="goDetail(item.id, '新闻与公告')" />
              <div class="carousel-title">
                <span @click="goDetail(item.id, '新闻与公告')">{{
                  item.title
                }}</span>
              </div>
            </el-carousel-item>
          </div>
        </el-carousel>
      </div>
      <ul class="home-list home-list1" style="width: 520px">
        <li v-for="(item, index) in news" :key="index" @click="goDetail(item.id, '新闻与公告')" v-show="index < 12">
          {{ item.title }}
        </li>
      </ul>
    </div>
    <div class="title cl">
      <div class="title-left">
        <span class="title-cooperate"></span>
        <span>成绩公告</span>
      </div>
      <div class="title-right" @click="$router.push({name:'list',params:{type:'新闻与公告',num:'成绩公告'}})">MORE+</div>
    </div>
    <div class="cl" style="height: 221px">
      <ul class="home-list home-list2" style="width: 568px;float: left">
        <li v-for="item in notice1" :key="item.id" @click="goDetail(item.id, '成绩公告')">
          <span class="list-name">{{ item.title }}</span>
          <span class="list-date">
            {{ conversionTimestamp(item.riqi) }}
          </span>
        </li>
      </ul>
      <ul class="home-list home-list2" style="width: 568px;float: right">
        <li v-for="item in notice2" :key="item.id" @click="goDetail(item.id, '成绩公告')">
          <span class="list-name">{{ item.title }}</span>
          <span class="list-date">
            {{ conversionTimestamp(item.riqi) }}
          </span>
        </li>
      </ul>
    </div>
    <div class="title cl">
      <div class="title-left">
        <span class="title-notice"></span>
        <span>合作伙伴</span>
      </div>
    </div>
    <ul class="home-list home-list3">
      <li v-for="item in cooperation" :key="item.id">
        <a :href="item.links" target="_blank">
          <img :src="baseImg + item.image" style="width: 100%;height: 100%" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { baseImg, mainNews, partner, getListT } from "../api/api";
// import util from "../libs/utils";
export default {
  name: "home",
  components: {},
  data() {
    return {
      notice1: "",
      notice2: "",
      cooperation: "",
      swiper: "",
      news: "",
      baseImg: baseImg
    };
  },
  methods: {
    init() {
      let params1 = `pageNo=1&pageSize=11 `;
      mainNews(params1).then(res => {
        this.news = res.result.data;
      });
      let params2 = `pageNo=1&pageSize=5&type=成绩公告`;
      getListT(params2).then(res => {
        this.notice1 = res.result.data;
      });
      let params3 = `pageNo=2&pageSize=5&type=成绩公告`;
      getListT(params3).then(res => {
        this.notice2 = res.result.data;
      });
      partner().then(res => {
        this.cooperation = res.result.data;
      });
    },
    goDetail(id, type) {
      this.$router.push({
        name: "detail",
        params: { id: id, type: type }
      });
    },
    goAct() {
      this.$router.push({
        name: "list",
        params: { type: "新闻与公告", num: 0 }
      });
    },
    conversionTimestamp(timestamp) {
      timestamp = parseInt(timestamp);
      var date = new Date(timestamp);
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return date.getFullYear() + "-" + month + "-" + day;
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped lang="less">
.title {
  .title-match {
    background: url("../images/match.png") no-repeat center;
  }
  .title-cooperate {
    background: url("../images/cooperate.png") no-repeat center;
  }
  .title-notice {
    background: url("../images/notice.png") no-repeat center;
  }
}

.home-list {
  font-size: 14px;
}

.home-list:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}

.home-list1 {
  margin: 10px 10px 0;
  float: right;
  li {
    list-style: inside;
    margin: 14px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.home-list2 {
  margin: 16px 10px 0;
  li {
    border-bottom: 1px solid #d1d1d1;
    padding: 12px 0;
    cursor: pointer;
    .list-name {
      float: left;
      width: 450px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .list-date {
      float: right;
    }
  }
  li:after {
    display: block;
    clear: both;
    content: "";
    visibility: hidden;
    height: 0;
  }
}

.home-list3 {
  li {
    width: 150px;
    height: 120px;
    float: left;
    margin: 20px 30px 0 10px;
    box-shadow: 5px 5px 15px 3px rgba(0, 0, 0, 0.2);
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
}

.carousel {
  float: left;
  width: 630px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .carousel-title {
    position: absolute;
    width: 100%;
    height: 64px;
    line-height: 64px;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
    bottom: 0;
    > span {
      display: inline-block;
      width: 380px;
      margin-left: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
  /deep/ .el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
  /deep/ .el-carousel__indicators--horizontal {
    bottom: 13px;
    right: 0;
    left: auto;
  }
}
</style>
